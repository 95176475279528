import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc, query, orderBy } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'

// Initialize Firestore database
const db = baseRepository.getDb()
const sponsorshipTiersCollectionRef = collection(db, 'sponsorship_tiers')

const getAllTiers = async () => {
  try {
    console.log('Fetching sponsorship tiers...')
    const q = query(sponsorshipTiersCollectionRef, orderBy('priority', 'desc'))
    console.log('Query created, executing...')
    const querySnapshot = await getDocs(q)
    console.log('Query executed, processing results...')
    const tiers = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    console.log('Tiers fetched:', tiers)
    return tiers
  } catch (error) {
    console.error('Error retrieving sponsorship tiers:', error)
    console.error('Error details:', {
      code: error.code,
      message: error.message,
      stack: error.stack,
    })
    throw error
  }
}

const createTier = async (tierData) => {
  try {
    const docRef = await addDoc(sponsorshipTiersCollectionRef, {
      ...tierData,
      createdAt: new Date(),
      updatedAt: new Date(),
    })
    return { id: docRef.id, ...tierData }
  } catch (error) {
    console.error('Error creating sponsorship tier:', error)
    throw error
  }
}

const updateTier = async (tierId, tierData) => {
  try {
    const tierRef = doc(db, 'sponsorship_tiers', tierId)
    await updateDoc(tierRef, {
      ...tierData,
      updatedAt: new Date(),
    })
    return { id: tierId, ...tierData }
  } catch (error) {
    console.error('Error updating sponsorship tier:', error)
    throw error
  }
}

const deleteTier = async (tierId) => {
  try {
    const tierRef = doc(db, 'sponsorship_tiers', tierId)
    await deleteDoc(tierRef)
    return tierId
  } catch (error) {
    console.error('Error deleting sponsorship tier:', error)
    throw error
  }
}

const sponsorshipTiersRepository = {
  getAllTiers,
  createTier,
  updateTier,
  deleteTier,
}

export default sponsorshipTiersRepository

import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyDZGFQVGYInNdXT6wq08pQNIpckaZZ6Iu4',
  authDomain: 'gay-camping-friends-8b94d.firebaseapp.com',
  projectId: 'gay-camping-friends-8b94d',
  storageBucket: 'gay-camping-friends-8b94d.appspot.com',
  messagingSenderId: '797143713665',
  appId: '1:797143713665:web:d22d4a8a38773f66c68a80',
  measurementId: 'G-P67T2B2Z74',
}

const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)

export default app

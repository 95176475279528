import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import sponsorsRepository from '../../../repositories/firestore/sponsors.firestore.repository'
import sponsorshipTiersRepository from '../../../repositories/firestore/sponsorshipTiers.firestore.repository'

const formatDate = (date) => {
  return new Date(date.seconds * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

const SponsorsList = () => {
  const [sponsors, setSponsors] = useState([])
  const [tiers, setTiers] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const [sponsorsData, tiersData] = await Promise.all([sponsorsRepository.getAllSponsors(), sponsorshipTiersRepository.getAllTiers()])
      setSponsors(sponsorsData)
      setTiers(tiersData)
    } catch (error) {
      toast.error('Failed to fetch data')
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (sponsorId) => {
    if (window.confirm('Are you sure you want to delete this sponsor?')) {
      try {
        await sponsorsRepository.deleteSponsor(sponsorId)
        toast.success('Sponsor deleted successfully')
        fetchData()
      } catch (error) {
        toast.error('Failed to delete sponsor')
        console.error('Error deleting sponsor:', error)
      }
    }
  }

  const getTierName = (tierId) => {
    const tier = tiers.find((t) => t.id === tierId)
    return tier ? tier.name : 'Unknown Tier'
  }

  if (loading) {
    return <div className='flex justify-center items-center h-64'>Loading...</div>
  }

  return (
    <div className='container mx-auto px-4 py-8'>
      <div className='flex justify-between items-center mb-6'>
        <h1 className='text-3xl font-bold'>Community Partners</h1>
        <button onClick={() => navigate('/admin/partners/add')} className='bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded'>
          Add New Partner
        </button>
      </div>

      <div className='bg-white shadow-md rounded-lg overflow-hidden'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Partner</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Logo</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Contact</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Tier</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Dates</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Status</th>
              <th className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>Actions</th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {sponsors.map((sponsor) => {
              const now = new Date()
              const startDate = new Date(sponsor.startDate.seconds * 1000)
              const endDate = new Date(sponsor.endDate.seconds * 1000)
              const status = now < startDate ? 'Upcoming' : now > endDate ? 'Expired' : 'Active'
              const statusColor = status === 'Active' ? 'green' : status === 'Upcoming' ? 'blue' : 'red'

              return (
                <tr key={sponsor.id}>
                  <td className='px-6 py-4 align-top'>
                    <div className='text-sm font-medium text-gray-900'>{sponsor.businessName}</div>
                    <div className='text-sm text-gray-500'>{sponsor.url}</div>
                  </td>
                  <td className='px-6 py-4 align-top'>
                    {sponsor.logo ? (
                      <img src={sponsor.logo} alt={sponsor.businessName} className='h-12 w-auto object-contain' />
                    ) : (
                      <span className='text-sm text-gray-500'>No logo</span>
                    )}
                  </td>
                  <td className='px-6 py-4 align-top'>
                    <div className='text-sm text-gray-900'>{sponsor.contactName}</div>
                    <div className='text-sm text-gray-500'>{sponsor.contactEmail}</div>
                    <div className='text-sm text-gray-500'>{sponsor.contactPhone}</div>
                  </td>
                  <td className='px-6 py-4 align-top'>
                    <div className='text-sm text-gray-900'>{getTierName(sponsor.tierId)}</div>
                  </td>
                  <td className='px-6 py-4 align-top'>
                    <div className='text-sm text-gray-500'>
                      <div>Start: {formatDate(sponsor.startDate)}</div>
                      <div>End: {formatDate(sponsor.endDate)}</div>
                    </div>
                  </td>
                  <td className='px-6 py-4 align-top'>
                    <span className={`inline-flex px-2 py-1 text-xs font-semibold rounded-full bg-${statusColor}-100 text-${statusColor}-800`}>
                      {status}
                    </span>
                  </td>
                  <td className='px-6 py-4 align-top text-right text-sm font-medium'>
                    <button onClick={() => navigate(`/admin/partners/edit/${sponsor.id}`)} className='text-blue-600 hover:text-blue-900 mr-4'>
                      Edit
                    </button>
                    <button onClick={() => handleDelete(sponsor.id)} className='text-red-600 hover:text-red-900'>
                      Delete
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SponsorsList

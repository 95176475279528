import storageRepository from './base.storage.repository'

export const uploadSponsorLogo = async (file, sponsorId) => {
  if (!file) throw new Error('No file provided for upload.')
  const directory = `sponsors/logos/${sponsorId}`
  const filePath = `${directory}/${file.name}`
  const downloadURL = await storageRepository.uploadFile(filePath, file)
  return downloadURL
}

export const deleteSponsorLogo = async (filePath) => {
  try {
    await storageRepository.deleteFile(filePath)
    return true
  } catch (error) {
    console.error(`Error deleting sponsor logo: ${filePath}`, error)
    throw error
  }
}

const sponsorsStorageRepository = {
  uploadSponsorLogo,
  deleteSponsorLogo,
}

export default sponsorsStorageRepository

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import sponsorsRepository from '../../../repositories/firestore/sponsors.firestore.repository'
import sponsorshipTiersRepository from '../../../repositories/firestore/sponsorshipTiers.firestore.repository'
import groupPhoto2 from '../../../img/photos/group-photo2.jpg'
import PageLayout from '../../shared/components/PageLayout'

const Sponsors = () => {
  const [sponsorsByTier, setSponsorsByTier] = useState({})
  const [tiers, setTiers] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeSection, setActiveSection] = useState('hero')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const [sponsorsData, tiersData] = await Promise.all([sponsorsRepository.getPublicSponsors(), sponsorshipTiersRepository.getAllTiers()])

      // Group sponsors by tier and sort alphabetically within each tier
      const groupedSponsors = sponsorsData.reduce((acc, sponsor) => {
        const tierId = sponsor.tierId
        if (!acc[tierId]) {
          acc[tierId] = []
        }
        acc[tierId].push(sponsor)
        return acc
      }, {})

      // Sort sponsors alphabetically within each tier
      Object.keys(groupedSponsors).forEach((tierId) => {
        groupedSponsors[tierId].sort((a, b) => a.businessName.localeCompare(b.businessName))
      })

      setSponsorsByTier(groupedSponsors)
      setTiers(tiersData.sort((a, b) => a.priority - b.priority))
    } catch (error) {
      toast.error('Failed to load sponsors')
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
      setActiveSection(sectionId)
    }
  }

  if (loading) {
    return <div className='flex justify-center items-center h-64'>Loading...</div>
  }

  // Filter out tiers with no sponsors
  const activeTiers = tiers.filter((tier) => sponsorsByTier[tier.id]?.length > 0)

  return (
    <PageLayout
      metaTitle='Our Partners - Gay Camping Friends'
      metaDescription='Meet the amazing partners who help make Gay Camping Friends possible and champion LGBTQ+ inclusion in the outdoors. Join our growing community of partners.'
      metaImage={groupPhoto2}
      showAds={false}>
      <div className='-mt-4 md:mt-0'>
        {/* Mobile Quick Nav */}
        <div className='md:hidden sticky top-0 z-50 bg-white shadow-md'>
          <div className='flex overflow-x-auto py-2 px-4 space-x-4 whitespace-nowrap'>
            <button
              onClick={() => scrollToSection('partners')}
              className={`px-3 py-1 text-sm rounded-full ${activeSection === 'partners' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-700'}`}>
              Partners
            </button>
            <button
              onClick={() => scrollToSection('join')}
              className={`px-3 py-1 text-sm rounded-full ${activeSection === 'join' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-700'}`}>
              Join Us
            </button>
          </div>
        </div>

        {/* Hero Section */}
        <div id='hero' className='relative h-[400px] overflow-hidden'>
          <div
            className='absolute inset-0 bg-center bg-cover'
            style={{
              backgroundImage: `url(${groupPhoto2})`,
            }}
          />
          <div className='absolute inset-0 bg-gray-900/70' />
          <div className='relative container mx-auto px-4 h-full flex items-center justify-center'>
            <div className='text-center'>
              <h1 className='text-4xl md:text-5xl font-bold mb-4 text-white'>Our Partners</h1>
              <p className='text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-gray-200'>
                Meet the organizations and individuals who partner with Gay Camping Friends to champion LGBTQ+ inclusion in the outdoors.
              </p>
              <Link
                to='/sponsorship'
                className='inline-block bg-gray-700 text-white px-8 py-3 rounded-lg hover:bg-gray-600 transition-colors text-lg font-semibold'>
                Become a Partner
              </Link>
            </div>
          </div>
        </div>

        {/* Partners List */}
        <div id='partners' className='container mx-auto px-4 py-16'>
          {activeTiers.length === 0 ? (
            <div className='text-center py-12'>
              <p className='text-xl text-gray-600 mb-8'>
                Be the first to partner with Gay Camping Friends and help us create more inclusive outdoor experiences!
              </p>
              <Link
                to='/sponsorship'
                className='inline-block bg-gray-700 text-white px-8 py-3 rounded-lg hover:bg-gray-600 transition-colors text-lg font-semibold'>
                View Partnership Opportunities
              </Link>
            </div>
          ) : (
            <div className='grid gap-16'>
              {activeTiers.map((tier) => (
                <div key={tier.id} className='space-y-8'>
                  <h2 className='text-3xl font-bold text-center text-gray-700'>{tier.name}</h2>
                  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                    {sponsorsByTier[tier.id].map((sponsor) => {
                      const SponsorContent = () => (
                        <>
                          {sponsor.logo && tier.logoSize > 0 ? (
                            <>
                              <img src={sponsor.logo} alt={sponsor.businessName} className='h-24 w-auto mx-auto mb-4 object-contain max-w-[200px]' />
                              <h3 className='text-xl font-semibold text-gray-700'>{sponsor.businessName}</h3>
                            </>
                          ) : (
                            <div className='h-24 flex items-center justify-center'>
                              <span className='text-xl font-semibold text-gray-700'>{sponsor.businessName}</span>
                            </div>
                          )}
                        </>
                      )

                      return sponsor.url ? (
                        <a
                          key={sponsor.id}
                          href={sponsor.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='bg-white p-6 rounded-lg shadow-sm text-center transform transition-transform hover:scale-105'>
                          <SponsorContent />
                        </a>
                      ) : (
                        <div key={sponsor.id} className='bg-white p-6 rounded-lg shadow-sm text-center'>
                          <SponsorContent />
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Call to Action */}
        <div id='join' className='bg-gray-50 py-16'>
          <div className='container mx-auto px-4 text-center'>
            <h2 className='text-3xl font-bold mb-6 text-gray-700'>Join Our Community</h2>
            <p className='text-xl text-gray-500 mb-8'>
              Partner with us to create more inclusive outdoor experiences and connect with the world's largest LGBTQ+ outdoor community.
            </p>
            <Link
              to='/sponsorship'
              className='inline-block bg-gray-700 text-white px-8 py-3 rounded-lg hover:bg-gray-600 transition-colors text-lg font-semibold'>
              View Partnership Tiers
            </Link>
          </div>
        </div>

        {/* Mobile Scroll To Top Button */}
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className='md:hidden fixed bottom-4 right-4 bg-gray-700 text-white p-3 rounded-full shadow-lg'>
          <svg className='w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 10l7-7m0 0l7 7m-7-7v18' />
          </svg>
        </button>
      </div>
    </PageLayout>
  )
}

export default Sponsors

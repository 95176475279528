import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc, query, orderBy, where, Timestamp } from 'firebase/firestore'
import baseRepository from './base.firestore.repository'
import { uploadSponsorLogo, deleteSponsorLogo } from '../storage/sponsors.storage.repository'

// Initialize Firestore database
const db = baseRepository.getDb()
const sponsorsCollectionRef = collection(db, 'sponsors')

const getAllSponsors = async () => {
  try {
    const q = query(sponsorsCollectionRef, orderBy('businessName'))
    const querySnapshot = await getDocs(q)
    const sponsors = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    return sponsors
  } catch (error) {
    console.error('Error retrieving sponsors:', error)
    throw error
  }
}

const getPublicSponsors = async () => {
  try {
    const currentDate = Timestamp.fromDate(new Date())

    // First, get sponsors that haven't ended yet
    const q = query(sponsorsCollectionRef, where('endDate', '>', currentDate), orderBy('endDate'), orderBy('businessName'))

    const querySnapshot = await getDocs(q)

    // Then filter in memory for sponsors that have already started
    const sponsors = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        businessName: doc.data().businessName,
        logo: doc.data().logo,
        url: doc.data().url,
        startDate: doc.data().startDate,
        endDate: doc.data().endDate,
        tierId: doc.data().tierId,
      }))
      .filter((sponsor) => sponsor.startDate <= currentDate)

    return sponsors
  } catch (error) {
    console.error('Error retrieving public sponsors:', error)
    throw error
  }
}

const createSponsor = async (sponsorData, logoFile) => {
  try {
    // Create the sponsor document first to get an ID
    const docRef = await addDoc(sponsorsCollectionRef, {
      ...sponsorData,
      logo: null, // Will update this after upload
      createdAt: Timestamp.fromDate(new Date()),
      updatedAt: Timestamp.fromDate(new Date()),
      startDate: Timestamp.fromDate(new Date(sponsorData.startDate)),
      endDate: Timestamp.fromDate(new Date(sponsorData.endDate)),
    })

    // Upload logo if provided
    let logoUrl = null
    if (logoFile) {
      logoUrl = await uploadSponsorLogo(logoFile, docRef.id)
      // Update the document with the logo URL
      await updateDoc(doc(db, 'sponsors', docRef.id), { logo: logoUrl })
    }

    return { id: docRef.id, ...sponsorData, logo: logoUrl }
  } catch (error) {
    console.error('Error creating sponsor:', error)
    throw error
  }
}

const updateSponsor = async (sponsorId, sponsorData, logoFile) => {
  try {
    const sponsorRef = doc(db, 'sponsors', sponsorId)
    const currentData = (await getDocs(query(collection(db, 'sponsors'), where('__name__', '==', sponsorId)))).docs[0].data()

    // Handle logo update
    let logoUrl = sponsorData.logo
    if (logoFile) {
      // Delete old logo if it exists
      if (currentData.logo) {
        try {
          await deleteSponsorLogo(currentData.logo)
        } catch (error) {
          console.warn('Error deleting old logo:', error)
        }
      }

      // Upload new logo
      logoUrl = await uploadSponsorLogo(logoFile, sponsorId)
    }

    await updateDoc(sponsorRef, {
      ...sponsorData,
      logo: logoUrl,
      updatedAt: Timestamp.fromDate(new Date()),
      startDate: Timestamp.fromDate(new Date(sponsorData.startDate)),
      endDate: Timestamp.fromDate(new Date(sponsorData.endDate)),
    })
    return { id: sponsorId, ...sponsorData, logo: logoUrl }
  } catch (error) {
    console.error('Error updating sponsor:', error)
    throw error
  }
}

const deleteSponsor = async (sponsorId) => {
  try {
    // Get sponsor data to delete logo
    const sponsorData = (await getDocs(query(collection(db, 'sponsors'), where('__name__', '==', sponsorId)))).docs[0].data()

    // Delete logo from storage if it exists
    if (sponsorData.logo) {
      try {
        await deleteSponsorLogo(sponsorData.logo)
      } catch (error) {
        console.warn('Error deleting logo:', error)
      }
    }

    const sponsorRef = doc(db, 'sponsors', sponsorId)
    await deleteDoc(sponsorRef)
    return sponsorId
  } catch (error) {
    console.error('Error deleting sponsor:', error)
    throw error
  }
}

const sponsorsRepository = {
  getAllSponsors,
  getPublicSponsors,
  createSponsor,
  updateSponsor,
  deleteSponsor,
}

export default sponsorsRepository

import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import sponsorshipTiersRepository from '../../../repositories/firestore/sponsorshipTiers.firestore.repository'
import groupPhoto from '../../../img/photos/group-photo.jpg'
import PageLayout from '../../shared/components/PageLayout'

const SponsorshipTiers = () => {
  const [tiers, setTiers] = useState([])
  const [loading, setLoading] = useState(true)
  const [openFaq, setOpenFaq] = useState(null)
  const [impactExpanded, setImpactExpanded] = useState(false)
  const [activeSection, setActiveSection] = useState('hero')

  const faqItems = [
    {
      question: 'How does the application process work?',
      answer:
        'GCF reviews all partnership applications to ensure alignment with our community values and mission. We aim to partner with organizations that share our commitment to LGBTQ+ inclusion and outdoor accessibility.',
    },
    {
      question: 'What is the duration of a partnership?',
      answer:
        'Partnerships run for 12 months from the start date. This allows for consistent, year-round engagement with our community and participation in our annual events calendar.',
    },
    {
      question: 'When and how are payments handled?',
      answer:
        'Payment is due annually and must be received before partnership benefits begin. We will work with you to establish a payment schedule that aligns with your fiscal planning.',
    },
    {
      question: 'What assets do I need to provide?',
      answer:
        'Partners must provide high-resolution logos and brand assets within brand guidelines. These will be used across our platform and event materials as specified in your partnership tier.',
    },
    {
      question: 'Are there deadlines for material submission?',
      answer:
        'Yes, inclusion of logos in event signage and materials may be subject to production deadlines. We will provide you with a clear timeline for asset submission to ensure your brand is properly represented.',
    },
    {
      question: 'What are the content guidelines?',
      answer:
        'All partners and partner content must align with GCFs mission of inclusivity and community values. We work closely with our partners to ensure messaging resonates with our community.',
    },
    {
      question: 'Will there be a formal agreement?',
      answer:
        'Yes, detailed terms and conditions will be provided in a partnership agreement depending on the tier. This ensures clarity and mutual understanding of all benefits and responsibilities.',
    },
    {
      question: 'Are partnership terms flexible?',
      answer:
        'While we have standard partnership tiers, we understand that each organization has unique needs and goals. For significant partnerships, we are open to discussing customized arrangements that could include modified benefits, special event opportunities, or other collaborative initiatives that align with our mission and community values.',
    },
    {
      question: 'Can I partner if my competitors are already partners?',
      answer:
        'Yes, we generally welcome partnerships from multiple organizations within the same industry. We believe in fostering an inclusive community where diverse businesses can contribute to our mission. For significant partnerships at higher tiers, we are open to discussing specific terms and arrangements that could include exclusivity rights.',
    },
    {
      question: 'Is Partnering with GCF Tax Deductible?',
      answer:
        'Gay Camping Friends is not a nonprofit, so partnerships are not considered charitable donations. However, many businesses can deduct partnership costs as an advertising or marketing expense since partnering provides brand exposure, promotional opportunities, and business benefits. We recommend consulting with a tax professional to determine how this applies to your business.',
    },
  ]

  useEffect(() => {
    fetchTiers()
  }, [])

  const fetchTiers = async () => {
    try {
      const tiersData = await sponsorshipTiersRepository.getAllTiers()
      // Sort by priority ascending (lowest number = highest priority)
      const sortedTiers = tiersData.sort((a, b) => a.priority - b.priority)
      setTiers(sortedTiers)
    } catch (error) {
      toast.error('Failed to load sponsorship information')
      console.error('Error fetching tiers:', error)
    } finally {
      setLoading(false)
    }
  }

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index)
  }

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
      setActiveSection(sectionId)
    }
  }

  if (loading) {
    return <div className='flex justify-center items-center h-64'>Loading...</div>
  }

  return (
    <PageLayout
      metaTitle='Support Gay Camping Friends - Partnership Opportunities'
      metaDescription='Partner with Gay Camping Friends to support LGBTQ+ inclusion in the outdoors. Learn about our partnership tiers and how your support creates impact in our community.'
      metaImage={groupPhoto}
      showAds={false}>
      <div className='-mt-4 md:mt-0'>
        {' '}
        {/* Negative margin to counteract PageLayout padding */}
        {/* Mobile Quick Nav */}
        <div className='md:hidden sticky top-0 z-50 bg-white shadow-md'>
          <div className='flex overflow-x-auto py-2 px-4 space-x-4 whitespace-nowrap'>
            <button
              onClick={() => scrollToSection('impact')}
              className={`px-3 py-1 text-sm rounded-full ${activeSection === 'impact' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-700'}`}>
              Impact
            </button>
            <button
              onClick={() => scrollToSection('tiers')}
              className={`px-3 py-1 text-sm rounded-full ${activeSection === 'tiers' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-700'}`}>
              Tiers
            </button>
            <button
              onClick={() => scrollToSection('faq')}
              className={`px-3 py-1 text-sm rounded-full ${activeSection === 'faq' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-700'}`}>
              FAQ
            </button>
            <button
              onClick={() => scrollToSection('contact')}
              className={`px-3 py-1 text-sm rounded-full ${activeSection === 'contact' ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-700'}`}>
              Contact
            </button>
          </div>
        </div>
        {/* Hero Section */}
        <div id='hero' className='relative h-[400px] overflow-hidden'>
          <div
            className='absolute inset-0 bg-center bg-cover'
            style={{
              backgroundImage: `url(${groupPhoto})`,
            }}
          />
          <div className='absolute inset-0 bg-gray-900/70' /> {/* Dark overlay */}
          <div className='relative h-full flex items-center justify-center'>
            <div className='text-center px-4'>
              <h1 className='text-4xl md:text-5xl font-bold mb-4 text-white'>Become a Community Partner</h1>
              <p className='text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-gray-200'>
                Join Gay Camping Friends in celebrating inclusive spaces and empowering the world's largest LGBTQ+ outdoors community.
              </p>
            </div>
          </div>
        </div>
        {/* Impact Section */}
        <div id='impact' className='container mx-auto px-4 py-16'>
          <h2 className='text-3xl font-bold text-center mb-12 text-gray-700'>How Your Partnership Creates Impact</h2>
          <p className='text-xl text-gray-600 text-center mb-12 max-w-4xl mx-auto'>
            Partnering with GCF isn't just about brand visibility—it's about empowering the world's largest LGBTQ+ outdoors community and supporting
            real change. Your support helps us:
          </p>

          {/* Mobile View */}
          <div className='md:hidden'>
            <button
              onClick={() => setImpactExpanded(!impactExpanded)}
              className='w-full flex justify-between items-center p-4 bg-white rounded-lg shadow-sm mb-4'>
              <span className='font-medium text-gray-700'>View Impact Details</span>
              <svg
                className={`w-5 h-5 text-gray-500 transform transition-transform ${impactExpanded ? 'rotate-180' : ''}`}
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
              </svg>
            </button>
            {impactExpanded && (
              <div className='grid gap-4'>
                <div className='bg-white p-8 rounded-lg shadow-sm'>
                  <div className='text-4xl mb-4'>🌍</div>
                  <h3 className='text-xl font-bold mb-4 text-gray-700'>Build and Strengthen the LGBTQ+ Outdoor Community</h3>
                  <ul className='space-y-3 text-gray-600'>
                    <li>• Support local meetups and smaller gatherings to create more inclusive spaces</li>
                    <li>• Provide resources to help members connect, share, and organize their own events</li>
                    <li>• Strengthen our online community with better tools and engagement</li>
                  </ul>
                </div>

                <div className='bg-white p-8 rounded-lg shadow-sm'>
                  <div className='text-4xl mb-4'>🎉</div>
                  <h3 className='text-xl font-bold mb-4 text-gray-700'>Improve and Expand Our Events</h3>
                  <ul className='space-y-3 text-gray-600'>
                    <li>• Cover operational costs and costs for planning and organizing meetups and larger gatherings</li>
                    <li>• Keep events free and accessible beyond just what's paid to host campgrounds</li>
                    <li>• Offer better experiences and entertainment</li>
                  </ul>
                </div>

                <div className='bg-white p-8 rounded-lg shadow-sm'>
                  <div className='text-4xl mb-4'>📢</div>
                  <h3 className='text-xl font-bold mb-4 text-gray-700'>Advocate for LGBTQ+ Inclusion in the Outdoors</h3>
                  <ul className='space-y-3 text-gray-600'>
                    <li>• Promote LGBTQ+ representation in outdoor recreation and travel spaces</li>
                    <li>• Partner with organizations and businesses to push for inclusive policies</li>
                    <li>• Provide educational resources and advocacy tools for members</li>
                  </ul>
                </div>

                <div className='bg-white p-8 rounded-lg shadow-sm'>
                  <div className='text-4xl mb-4'>🚀</div>
                  <h3 className='text-xl font-bold mb-4 text-gray-700'>Ambitious Goals for the Future</h3>
                  <ul className='space-y-3 text-gray-600'>
                    <li>• Develop new opportunities for LGBTQ+ campers to connect</li>
                    <li>• Invest in expanded digital tools and resources for members</li>
                    <li>• Explore larger-scale initiatives that secure dedicated LGBTQ+ outdoor spaces</li>
                  </ul>
                </div>
              </div>
            )}
          </div>

          {/* Desktop View */}
          <div className='hidden md:grid md:grid-cols-2 gap-8'>
            <div className='bg-white p-8 rounded-lg shadow-sm'>
              <div className='text-4xl mb-4'>🌍</div>
              <h3 className='text-xl font-bold mb-4 text-gray-700'>Build and Strengthen the LGBTQ+ Outdoor Community</h3>
              <ul className='space-y-3 text-gray-600'>
                <li>• Support local meetups and smaller gatherings to create more inclusive spaces</li>
                <li>• Provide resources to help members connect, share, and organize their own events</li>
                <li>• Strengthen our online community with better tools and engagement</li>
              </ul>
            </div>

            <div className='bg-white p-8 rounded-lg shadow-sm'>
              <div className='text-4xl mb-4'>🎉</div>
              <h3 className='text-xl font-bold mb-4 text-gray-700'>Improve and Expand Our Events</h3>
              <ul className='space-y-3 text-gray-600'>
                <li>• Cover operational costs and costs for planning and organizing meetups and larger gatherings</li>
                <li>• Keep events free and accessible beyond just what's paid to host campgrounds</li>
                <li>• Offer better experiences and entertainment</li>
              </ul>
            </div>

            <div className='bg-white p-8 rounded-lg shadow-sm'>
              <div className='text-4xl mb-4'>📢</div>
              <h3 className='text-xl font-bold mb-4 text-gray-700'>Advocate for LGBTQ+ Inclusion in the Outdoors</h3>
              <ul className='space-y-3 text-gray-600'>
                <li>• Promote LGBTQ+ representation in outdoor recreation and travel spaces</li>
                <li>• Partner with organizations and businesses to push for inclusive policies</li>
                <li>• Provide educational resources and advocacy tools for members</li>
              </ul>
            </div>

            <div className='bg-white p-8 rounded-lg shadow-sm'>
              <div className='text-4xl mb-4'>🚀</div>
              <h3 className='text-xl font-bold mb-4 text-gray-700'>Ambitious Goals for the Future</h3>
              <ul className='space-y-3 text-gray-600'>
                <li>• Develop new opportunities for LGBTQ+ campers to connect</li>
                <li>• Invest in expanded digital tools and resources for members</li>
                <li>• Explore larger-scale initiatives that secure dedicated LGBTQ+ outdoor spaces</li>
              </ul>
            </div>
          </div>
        </div>
        {/* Pricing Tiers */}
        <div id='tiers' className='bg-gray-50'>
          <div className='container mx-auto px-4 py-16'>
            <h2 className='text-3xl font-bold text-center mb-12 text-gray-700'>Partnership Tiers</h2>
            <div className='grid md:grid-cols-3 gap-8'>
              {tiers.map((tier) => (
                <div key={tier.id} className='bg-white rounded-lg shadow-sm overflow-hidden transform transition-transform hover:scale-105'>
                  <div className='bg-gray-700 text-white py-6 px-8'>
                    <h3 className='text-2xl font-bold text-center'>{tier.name}</h3>
                    <div className='text-center mt-4'>
                      <span className='text-4xl font-bold'>${tier.cost.toLocaleString()}</span>
                      <span className='text-sm text-gray-200'>/year</span>
                    </div>
                  </div>
                  <div className='p-8'>
                    <ul className='space-y-4'>
                      {tier.perks.map((perk, index) => (
                        <li key={index} className='flex items-start'>
                          <svg
                            className='h-6 w-6 text-gray-500 mr-2'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            viewBox='0 0 24 24'
                            stroke='currentColor'>
                            <path d='M5 13l4 4L19 7'></path>
                          </svg>
                          <span className='text-gray-500'>{perk}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* FAQ Section */}
        <div id='faq' className='container mx-auto px-4 py-16'>
          <h2 className='text-2xl font-bold text-center mb-8 text-gray-700'>Frequently Asked Questions</h2>
          <div className='mx-auto'>
            {faqItems.map((faq, index) => (
              <div key={index} className='mb-4'>
                <button
                  onClick={() => toggleFaq(index)}
                  className='w-full flex justify-between items-center p-4 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition-colors'>
                  <span className='text-left font-medium text-gray-700'>{faq.question}</span>
                  <svg
                    className={`w-5 h-5 text-gray-500 transform transition-transform ${openFaq === index ? 'rotate-180' : ''}`}
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
                  </svg>
                </button>
                {openFaq === index && (
                  <div className='mt-2 p-4 bg-gray-50 rounded-lg'>
                    <p className='text-gray-600'>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {/* Contact Section */}
        <div id='contact' className='bg-gray-100 py-12'>
          <div className='container mx-auto px-4 text-center'>
            <h2 className='text-3xl font-bold mb-6 text-gray-700'>Join Us in Making a Difference</h2>
            <p className='text-xl text-gray-500 mb-8'>
              Partner with Gay Camping Friends to promote LGBTQ+ inclusion in the outdoors and help us create more memorable events for our community.
            </p>
            <a
              href='mailto:info@gaycampingfriends.com?subject=Partnership%20Inquiry'
              className='inline-block bg-gray-700 text-white px-8 py-3 rounded-lg hover:bg-gray-600 transition-colors text-lg font-semibold'>
              Contact Us About Partnership
            </a>
          </div>
        </div>
        {/* Mobile Scroll To Top Button */}
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className='md:hidden fixed bottom-4 right-4 bg-gray-700 text-white p-3 rounded-full shadow-lg'>
          <svg className='w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 10l7-7m0 0l7 7m-7-7v18' />
          </svg>
        </button>
      </div>
    </PageLayout>
  )
}

export default SponsorshipTiers

import React from 'react'
import { FaBuilding, FaCalendarAlt, FaUserFriends, FaCommentAlt, FaFlask, FaStar, FaHandshake } from 'react-icons/fa'
import PageLayout from '../../shared/components/PageLayout'
import DashboardTile from '../../shared/components/DashboardTile'

const AdminHome = () => {
  return (
    <PageLayout title='Admin Dashboard'>
      <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-4'>
        <DashboardTile link='/admin/campgrounds' icon={FaBuilding} title='Manage Campgrounds' />
        <DashboardTile link='/admin/events' icon={FaCalendarAlt} title='Manage Events' />
        <DashboardTile link='/admin/members' icon={FaUserFriends} title='Manage Members' />
        <DashboardTile link='/admin/chatrooms' icon={FaCommentAlt} title='Manage Chat Rooms' />
        <DashboardTile link='/admin/sponsorship' icon={FaStar} title='Manage Sponsorship Tiers' />
        <DashboardTile link='/admin/sponsors' icon={FaHandshake} title='Manage Sponsors' />
        <DashboardTile link='/admin/chat/test-chat' icon={FaFlask} title='Test Chat' />
      </div>
    </PageLayout>
  )
}

export default AdminHome

// React and library imports
import React from 'react'
import { BrowserRouter as Router, Routes, Route, useParams, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Configuration and utilities
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './services/firebase/config'

// Context providers
import { AuthProvider } from './contexts/AuthContext'

// Components and pages
import Layout from './pages/shared/components/Layout'
import Home from './pages/frontend/home/Home'
import Campground from './pages/frontend/campgrounds/campground/Campground'
import CampgroundCategory from './pages/frontend/campgrounds/campgroundCategory/CampgroundCategory'
import Blog from './pages/frontend/blogs/blog/Blog'
import BlogDetails from './pages/frontend/blogs/blogDetails/BlogDetails'
import Events from './pages/frontend/events/eventsHome/Events'
import EventDetails from './pages/frontend/events/eventDetails/EventDetails'
import SignupForm from './pages/frontend/auth/signUp/SignupForm'
import LoginForm from './pages/frontend/auth/login/LoginForm'
import AdminHome from './pages/admin/dashboard/AdminHome'
import CampgroundsList from './pages/admin/campgrounds/campgrounds/CampgroundsList'
import CampgroundForm from './pages/admin/campgrounds/campground/CampgroundForm'
import EditProfileForm from './pages/user/editProfile/EditProfileForm'
import EventsList from './pages/admin/events/events/EventsList'
import EventForm from './pages/admin/events/event/EventForm'
import EditAccountForm from './pages/user/editAccount/EditAccountForm'
import About from './pages/frontend/about/About'
import PublicProfile from './pages/user/profile/PublicProfile'
import Resources from './pages/frontend/resources/Resources'
import MembersList from './pages/admin/members/members/MembersList'
import UserHome from './pages/user/dashboard/UserHome'
import Leaderboard from './pages/user/leaderboard/Leaderboard'
import TripForm from './pages/user/trips/TripForm'
import MemberDirectory from './pages/user/directory/MemberDirectory'
import PrivacyPolicy from './pages/frontend/privacyPolicy/PrivacyPolicy'
import TermsConditions from './pages/frontend/termsConditions/TermsConditions'
import TripsFeed from './pages/user/feed/TripsFeed'
import CampgroundEventForm from './pages/admin/events/event/CampgroundEventForm'
import CampgroundAdminDashboard from './pages/campground-admin/dashboard/CampgroundAdminDashboard'
import ManageMember from './pages/admin/members/member/ManageMember'
import CampgroundAdminEventsList from './pages/campground-admin/events/events/CampgroundAdminEventsList'
import CampgroundAdminEventForm from './pages/campground-admin/events/event/CampgroundEventForm'
import CampgroundAdminDetailsForm from './pages/campground-admin/campground/CampgroundAdminDetailsForm'
import ThisWeekEvents from './pages/frontend/thisWeek/ThisWeekEvents'
import RedirectCampgroundToSlug from './routing/RedirectCampgroundToSlug'
import CampgroundEventDetails from './pages/frontend/events/campgroundEventDetails/CampgroundEventDetails'
import ChatRoomsList from './pages/admin/chat/chatRooms/ChatRoomsList'
import ChatRoomForm from './pages/admin/chat/chatRoom/ChatRoomForm'
import ChatContainer from './pages/user/chat/ChatContainer'
import SponsorshipTiersList from './pages/admin/sponsorship/SponsorshipTiersList'
import SponsorshipTierForm from './pages/admin/sponsorship/SponsorshipTierForm'
import SponsorsList from './pages/admin/sponsors/SponsorsList'
import SponsorForm from './pages/admin/sponsors/SponsorForm'
import SponsorshipTiers from './pages/public/sponsorship/SponsorshipTiers'
import Sponsors from './pages/public/sponsors/Sponsors'

// Routing
import ProtectedRoute from './routing/ProtectedRoute'
import CampgroundAdminRoute from './routing/CampgroundProtectedRoute'

// Assets
import './index.css'
import BlockedUsers from './pages/user/blocked/BlockedUsers'
import ForumList from './pages/user/forum/ForumList'
import ThreadList from './pages/user/forum/ThreadList'
import PostList from './pages/user/forum/PostList'
import AddForum from './pages/user/forum/AddForum'
import AddThread from './pages/user/forum/AddThread'
import AddPost from './pages/user/forum/AddPost'

// Initialize Firebase
const app = initializeApp(firebaseConfig)

function RedirectListingToCampground() {
  let { slug } = useParams()
  return <Navigate to={`/campground/${slug}`} replace />
}

//const analytics = getAnalytics(app)

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            {/* FRONT END SCREENS */}
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/resources' element={<Resources />} />
            <Route path='/partnership' element={<SponsorshipTiers />} />
            <Route path='/partners' element={<Sponsors />} />
            <Route path='/listing/:slug' element={<RedirectListingToCampground />} />
            <Route path='/campground/:slug' element={<Campground />} />
            <Route path='/campground-id/:campgroundId' element={<RedirectCampgroundToSlug />} />
            <Route path='/campground-category/:slug' element={<CampgroundCategory />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/:slug' element={<BlogDetails />} />
            <Route path='/events' element={<Events />} />
            <Route path='/events/:id' element={<EventDetails />} />
            <Route path='/campground-event/:id' element={<CampgroundEventDetails />} />
            <Route path='/this-week' element={<ThisWeekEvents />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-conditions' element={<TermsConditions />} />

            {/* AUTH SCREENS */}
            <Route path='/auth/signup' element={<SignupForm />} />
            <Route path='/auth/login' element={<LoginForm />} />

            {/* USER SCREENS */}
            <Route path='/user/dashboard' element={<ProtectedRoute component={UserHome} />} />
            <Route path='/user/edit-profile' element={<ProtectedRoute component={EditProfileForm} />} />
            <Route path='/user/edit-account' element={<ProtectedRoute component={EditAccountForm} />} />
            <Route path='/user/leaderboard' element={<ProtectedRoute component={Leaderboard} />} />
            <Route path='/user/profile/:userId' element={<ProtectedRoute component={PublicProfile} />} />
            <Route path='/user/trips' element={<ProtectedRoute component={TripForm} />} />
            <Route path='/user/directory' element={<ProtectedRoute component={MemberDirectory} />} />
            <Route path='/user/feed' element={<ProtectedRoute component={TripsFeed} />} />
            {/* <Route path='/user/chat/:chatRoomName' element={<ProtectedRoute component={ChatRoomLayoutTest} />} /> */}
            <Route path='/user/chat' element={<ProtectedRoute component={ChatContainer} />} />
            <Route path='/user/chat/:chatId' element={<ProtectedRoute component={ChatContainer} />} />
            <Route path='/user/blocked' element={<ProtectedRoute component={BlockedUsers} />} />

            <Route path='/user/forums' element={<ProtectedRoute component={ForumList} />} />
            <Route path='/user/forums/add' element={<ProtectedRoute component={AddForum} />} />
            <Route path='/user/forums/:forumId/edit' element={<ProtectedRoute component={AddForum} />} />
            <Route path='/user/forums/:forumId' element={<ProtectedRoute component={ThreadList} />} />
            <Route path='/user/forums/:forumId/add-thread' element={<ProtectedRoute component={AddThread} />} />
            <Route path='/user/forums/:forumId/threads/:threadId/edit' element={<ProtectedRoute component={AddThread} />} />
            <Route path='/user/forums/:forumId/threads/:threadId' element={<ProtectedRoute component={PostList} />} />
            <Route path='/user/forums/:forumId/threads/:threadId/add-post' element={<ProtectedRoute component={AddPost} />} />

            {/* CAMPGROUND ADMIN SCREENS */}
            <Route path='/campground-admin/:campgroundId' element={<CampgroundAdminRoute />}>
              <Route index element={<CampgroundAdminDashboard />} />
              <Route path='events' element={<CampgroundAdminEventsList />} />
              <Route path='event' element={<CampgroundAdminEventForm />} />
              <Route path='event/:eventId' element={<CampgroundAdminEventForm />} />
              <Route path='details' element={<CampgroundAdminDetailsForm />} />
            </Route>

            {/* ADMIN SCREENS */}
            <Route path='/admin' element={<ProtectedRoute roles={['admin']} component={AdminHome} />} />
            <Route path='/admin/partnership' element={<ProtectedRoute roles={['admin']} component={SponsorshipTiersList} />} />
            <Route path='/admin/partnership/tier' element={<ProtectedRoute roles={['admin']} component={SponsorshipTierForm} />} />
            <Route path='/admin/partnership/tier/:tierId' element={<ProtectedRoute roles={['admin']} component={SponsorshipTierForm} />} />
            <Route path='/admin/partners' element={<ProtectedRoute roles={['admin']} component={SponsorsList} />} />
            <Route path='/admin/partners/add' element={<ProtectedRoute roles={['admin']} component={SponsorForm} />} />
            <Route path='/admin/partners/edit/:sponsorId' element={<ProtectedRoute roles={['admin']} component={SponsorForm} />} />
            <Route path='/admin/campgrounds' element={<ProtectedRoute roles={['admin']} component={CampgroundsList} />} />
            <Route path='/admin/campground' element={<ProtectedRoute roles={['admin']} component={CampgroundForm} />} />
            <Route path='/admin/campground/:campgroundId' element={<ProtectedRoute roles={['admin']} component={CampgroundForm} />} />
            <Route path='/admin/events' element={<ProtectedRoute roles={['admin']} component={EventsList} />} />
            <Route path='/admin/event' element={<ProtectedRoute roles={['admin']} component={EventForm} />} />
            <Route path='/admin/campground-event' element={<ProtectedRoute roles={['admin']} component={CampgroundEventForm} />} />
            <Route path='/admin/event/:eventId' element={<ProtectedRoute roles={['admin']} component={EventForm} />} />
            <Route path='/admin/members' element={<ProtectedRoute roles={['admin']} component={MembersList} />} />
            <Route path='/admin/member/:userId' element={<ProtectedRoute roles={['admin']} component={ManageMember} />} />
            <Route path='/admin/chatrooms' element={<ProtectedRoute roles={['admin']} component={ChatRoomsList} />} />
            <Route path='/admin/chatroom' element={<ProtectedRoute roles={['admin']} component={ChatRoomForm} />} />
            <Route path='/admin/chatroom/:chatRoomId' element={<ProtectedRoute roles={['admin']} component={ChatRoomForm} />} />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  )
}

export default App

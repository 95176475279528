import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import sponsorsRepository from '../../../repositories/firestore/sponsors.firestore.repository'
import sponsorshipTiersRepository from '../../../repositories/firestore/sponsorshipTiers.firestore.repository'

const SponsorForm = () => {
  const { sponsorId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(sponsorId ? true : false)
  const [logoFile, setLogoFile] = useState(null)
  const [logoPreview, setLogoPreview] = useState(null)
  const [tiers, setTiers] = useState([])
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const fetchTiers = async () => {
      try {
        const tiersData = await sponsorshipTiersRepository.getAllTiers()
        setTiers(tiersData)
      } catch (error) {
        console.error('Error fetching tiers:', error)
        toast.error('Failed to fetch sponsorship tiers')
      }
    }

    fetchTiers()
    if (sponsorId) {
      fetchSponsorData()
    }
  }, [sponsorId])

  const fetchSponsorData = async () => {
    try {
      const sponsors = await sponsorsRepository.getAllSponsors()
      const sponsor = sponsors.find((s) => s.id === sponsorId)
      if (sponsor) {
        setValue('businessName', sponsor.businessName)
        setValue('address', sponsor.address)
        setValue('contactEmail', sponsor.contactEmail)
        setValue('contactPhone', sponsor.contactPhone)
        setValue('contactName', sponsor.contactName)
        setValue('url', sponsor.url)
        setValue('tierId', sponsor.tierId)
        setValue('startDate', new Date(sponsor.startDate.seconds * 1000).toISOString().split('T')[0])
        setValue('endDate', new Date(sponsor.endDate.seconds * 1000).toISOString().split('T')[0])
        setLogoPreview(sponsor.logo)
      }
    } catch (error) {
      toast.error('Failed to fetch sponsor details')
      console.error('Error fetching sponsor:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleLogoChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setLogoFile(file)
      setLogoPreview(URL.createObjectURL(file))
    }
  }

  const onSubmit = async (data) => {
    try {
      if (sponsorId) {
        await sponsorsRepository.updateSponsor(sponsorId, data, logoFile)
        toast.success('Partner updated successfully')
      } else {
        await sponsorsRepository.createSponsor(data, logoFile)
        toast.success('Partner created successfully')
      }
      navigate('/admin/partners')
    } catch (error) {
      toast.error(sponsorId ? 'Failed to update partner' : 'Failed to create partner')
      console.error('Error saving partner:', error)
    }
  }

  if (loading) {
    return <div className='flex justify-center items-center h-64'>Loading...</div>
  }

  return (
    <div className='container mx-auto px-4 py-8'>
      <h1 className='text-3xl font-bold mb-6'>{sponsorId ? 'Edit Community Partner' : 'Add New Community Partner'}</h1>

      <form onSubmit={handleSubmit(onSubmit)} className='max-w-2xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Partner Name</label>
          <input
            {...register('businessName', { required: 'Partner name is required' })}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            type='text'
          />
          {errors.businessName && <p className='text-red-500 text-xs italic'>{errors.businessName.message}</p>}
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Logo</label>
          <input
            type='file'
            accept='image/*'
            onChange={handleLogoChange}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          />
          {logoPreview && (
            <div className='mt-2'>
              <img src={logoPreview} alt='Logo preview' className='h-24 w-auto object-contain' />
            </div>
          )}
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Address</label>
          <textarea
            {...register('address')}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            rows='3'
          />
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>Contact Name</label>
            <input
              {...register('contactName', { required: 'Contact name is required' })}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              type='text'
            />
            {errors.contactName && <p className='text-red-500 text-xs italic'>{errors.contactName.message}</p>}
          </div>

          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>Contact Email</label>
            <input
              {...register('contactEmail', {
                required: 'Contact email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              type='email'
            />
            {errors.contactEmail && <p className='text-red-500 text-xs italic'>{errors.contactEmail.message}</p>}
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>Contact Phone</label>
            <input
              {...register('contactPhone')}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              type='tel'
            />
          </div>

          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>Website URL (Optional)</label>
            <input
              {...register('url', {
                pattern: {
                  value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
                  message: 'Invalid URL format',
                },
                validate: {
                  validUrl: (value) => !value || /^https?:\/\//.test(value) || 'URL must start with http:// or https://',
                },
              })}
              placeholder='https://example.com'
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              type='url'
            />
            {errors.url && <p className='text-red-500 text-xs italic'>{errors.url.message}</p>}
          </div>
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Partnership Tier</label>
          <select
            {...register('tierId', { required: 'Partnership tier is required' })}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'>
            <option value=''>Select a tier</option>
            {tiers.map((tier) => (
              <option key={tier.id} value={tier.id}>
                {tier.name} - {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tier.cost)}
              </option>
            ))}
          </select>
          {errors.tierId && <p className='text-red-500 text-xs italic'>{errors.tierId.message}</p>}
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>Start Date</label>
            <input
              {...register('startDate', { required: 'Start date is required' })}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              type='date'
            />
            {errors.startDate && <p className='text-red-500 text-xs italic'>{errors.startDate.message}</p>}
          </div>

          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>End Date</label>
            <input
              {...register('endDate', { required: 'End date is required' })}
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              type='date'
            />
            {errors.endDate && <p className='text-red-500 text-xs italic'>{errors.endDate.message}</p>}
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <button
            type='submit'
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
            {sponsorId ? 'Update Partner' : 'Create Partner'}
          </button>
          <button
            type='button'
            onClick={() => navigate('/admin/partners')}
            className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default SponsorForm

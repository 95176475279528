import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import sponsorshipTiersRepository from '../../../repositories/firestore/sponsorshipTiers.firestore.repository'

const SponsorshipTierForm = () => {
  const { tierId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(tierId ? true : false)
  const [perks, setPerks] = useState([''])
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (tierId) {
      fetchTierData()
    }
  }, [tierId])

  const fetchTierData = async () => {
    try {
      const tiers = await sponsorshipTiersRepository.getAllTiers()
      const tier = tiers.find((t) => t.id === tierId)
      if (tier) {
        setValue('name', tier.name)
        setValue('priority', tier.priority)
        setValue('cost', tier.cost)
        setValue('logoSize', tier.logoSize)
        setPerks(tier.perks || [''])
      }
    } catch (error) {
      toast.error('Failed to fetch tier details')
      console.error('Error fetching tier:', error)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (data) => {
    const filteredPerks = perks.filter((perk) => perk.trim() !== '')
    const tierData = {
      ...data,
      perks: filteredPerks,
      priority: parseInt(data.priority),
      cost: parseFloat(data.cost),
      logoSize: parseInt(data.logoSize),
    }

    try {
      if (tierId) {
        await sponsorshipTiersRepository.updateTier(tierId, tierData)
        toast.success('Sponsorship tier updated successfully')
      } else {
        await sponsorshipTiersRepository.createTier(tierData)
        toast.success('Sponsorship tier created successfully')
      }
      navigate('/admin/sponsorship')
    } catch (error) {
      toast.error(tierId ? 'Failed to update tier' : 'Failed to create tier')
      console.error('Error saving tier:', error)
    }
  }

  const handleAddPerk = () => {
    setPerks([...perks, ''])
  }

  const handlePerkChange = (index, value) => {
    const newPerks = [...perks]
    newPerks[index] = value
    setPerks(newPerks)
  }

  const handleRemovePerk = (index) => {
    const newPerks = perks.filter((_, i) => i !== index)
    setPerks(newPerks)
  }

  if (loading) {
    return <div className='flex justify-center items-center h-64'>Loading...</div>
  }

  return (
    <div className='container mx-auto px-4 py-8'>
      <h1 className='text-3xl font-bold mb-6'>{tierId ? 'Edit Sponsorship Tier' : 'Create Sponsorship Tier'}</h1>

      <form onSubmit={handleSubmit(onSubmit)} className='max-w-2xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Tier Name</label>
          <input
            {...register('name', { required: 'Tier name is required' })}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            type='text'
            placeholder='Enter tier name'
          />
          {errors.name && <p className='text-red-500 text-xs italic'>{errors.name.message}</p>}
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Priority (Lower Number = Higher Priority)</label>
          <input
            {...register('priority', {
              required: 'Priority is required',
              min: { value: 1, message: 'Priority must be at least 1' },
            })}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            type='number'
            placeholder='Enter priority number'
          />
          {errors.priority && <p className='text-red-500 text-xs italic'>{errors.priority.message}</p>}
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Cost ($)</label>
          <input
            {...register('cost', {
              required: 'Cost is required',
              min: { value: 0, message: 'Cost must be at least 0' },
            })}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            type='number'
            step='0.01'
            placeholder='Enter cost'
          />
          {errors.cost && <p className='text-red-500 text-xs italic'>{errors.cost.message}</p>}
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Logo Size (px) - Enter 0 for no logo</label>
          <input
            {...register('logoSize', {
              required: 'Logo size is required',
              min: { value: 0, message: 'Logo size cannot be negative' },
            })}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            type='number'
            placeholder='Enter logo size in pixels (0 for no logo)'
          />
          {errors.logoSize && <p className='text-red-500 text-xs italic'>{errors.logoSize.message}</p>}
        </div>

        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2'>Perks</label>
          {perks.map((perk, index) => (
            <div key={index} className='flex mb-2'>
              <input
                value={perk}
                onChange={(e) => handlePerkChange(index, e.target.value)}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2'
                type='text'
                placeholder='Enter perk'
              />
              <button
                type='button'
                onClick={() => handleRemovePerk(index)}
                className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                Remove
              </button>
            </div>
          ))}
          <button type='button' onClick={handleAddPerk} className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'>
            Add Perk
          </button>
        </div>

        <div className='flex items-center justify-between'>
          <button
            type='submit'
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
            {tierId ? 'Update Tier' : 'Create Tier'}
          </button>
          <button
            type='button'
            onClick={() => navigate('/admin/sponsorship')}
            className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default SponsorshipTierForm

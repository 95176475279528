import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import sponsorshipTiersRepository from '../../../repositories/firestore/sponsorshipTiers.firestore.repository'

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
}

const SponsorshipTiersList = () => {
  const [tiers, setTiers] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    fetchTiers()
  }, [])

  const fetchTiers = async () => {
    try {
      const tiersData = await sponsorshipTiersRepository.getAllTiers()
      // Sort by priority ascending (lowest first)
      const sortedTiers = tiersData.sort((a, b) => a.priority - b.priority)
      setTiers(sortedTiers)
    } catch (error) {
      toast.error('Failed to fetch sponsorship tiers')
      console.error('Error fetching tiers:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (tierId) => {
    if (window.confirm('Are you sure you want to delete this sponsorship tier?')) {
      try {
        await sponsorshipTiersRepository.deleteTier(tierId)
        toast.success('Sponsorship tier deleted successfully')
        fetchTiers()
      } catch (error) {
        toast.error('Failed to delete sponsorship tier')
        console.error('Error deleting tier:', error)
      }
    }
  }

  if (loading) {
    return <div className='flex justify-center items-center h-64'>Loading...</div>
  }

  return (
    <div className='container mx-auto px-4 py-8'>
      <div className='flex justify-between items-center mb-6'>
        <h1 className='text-3xl font-bold'>Partnership Tiers</h1>
        <button onClick={() => navigate('/admin/sponsorship/tier')} className='bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded'>
          Add New Tier
        </button>
      </div>

      <div className='bg-white shadow-md rounded-lg overflow-hidden'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Name</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Priority</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Cost</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Logo Size</th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Benefits</th>
              <th className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>Actions</th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {tiers.map((tier) => (
              <tr key={tier.id}>
                <td className='px-6 py-4 align-top'>
                  <div className='text-sm font-medium text-gray-900'>{tier.name}</div>
                </td>
                <td className='px-6 py-4 align-top'>
                  <div className='text-sm text-gray-500'>{tier.priority}</div>
                </td>
                <td className='px-6 py-4 align-top'>
                  <div className='text-sm text-gray-500'>{formatCurrency(tier.cost)}</div>
                </td>
                <td className='px-6 py-4 align-top'>
                  <div className='text-sm text-gray-500'>{tier.logoSize > 0 ? `${tier.logoSize}px` : 'None'}</div>
                </td>
                <td className='px-6 py-4 align-top'>
                  <div className='text-sm text-gray-500'>
                    <ul className='list-disc list-inside'>
                      {tier.perks &&
                        tier.perks.map((perk, index) => (
                          <li key={index} className='truncate max-w-xs'>
                            {perk}
                          </li>
                        ))}
                    </ul>
                  </div>
                </td>
                <td className='px-6 py-4 align-top text-right text-sm font-medium'>
                  <button onClick={() => navigate(`/admin/sponsorship/tier/${tier.id}`)} className='text-blue-600 hover:text-blue-900 mr-4'>
                    Edit
                  </button>
                  <button onClick={() => handleDelete(tier.id)} className='text-red-600 hover:text-red-900'>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SponsorshipTiersList
